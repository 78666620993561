import React from "react";
import { useRef } from "react";
import * as css from "../styles/Slideshow.module.scss";
import * as cssButton from "../styles/ButtonBase.module.scss";
import { classs } from "../utils/utils";
import ButtonBase from "../components/ButtonBase";
import ArrowRight from "@mdi/svg/svg/arrow-right-circle.svg";

const Slideshow = ({
  className,
  children,
  num: num_,
  fade = 1,
  visible = 2,
}) => {
  const num = num_ ?? children?.length;
  const name = `slideshow_${num}_${fade}_${visible}_fade`;
  const anim = (len) => 100 / ((fade + visible) * len);
  const ref = useRef();
  const to = (dir) => () => {
    ref.current?.getAnimations({ subtree: true })?.forEach((anim) => {
      const time = anim.currentTime + dir * (fade + visible) * 1000;
      const max = (fade + visible) * 1000 * num;
      anim.currentTime = time < 0 ? max : time;
    });
  };

  return (
    <div
      className={classs(css.Slideshow, className)}
      style={{
        "--items-count": num,
        "--animation-name": name,
        "--fade-duration": fade,
        "--visible-duration": visible,
      }}
      ref={ref}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
    @keyframes ${name} {
      0%   { opacity: 0; pointer-events: none; }
      ${anim(num) * fade}%   { opacity: 1; pointer-events: all; }
      ${anim(num) * (fade + visible)}%  { opacity: 1; pointer-events: all; }
      ${
        anim(num) * (2 * fade + visible)
      }%  { opacity: 0; pointer-events: none; }
      100% { opacity: 0; pointer-events: none; }
    }`,
        }}
      />
      {children}
      <div className="controls">
        <ButtonBase className={cssButton.IconButton} onClick={to(-1)}>
          <ArrowRight
            style={{ transform: "rotate(180deg)", marginRight: "4px" }}
          />
        </ButtonBase>
        <ButtonBase className={cssButton.IconButton} onClick={to(+1)}>
          <ArrowRight />
        </ButtonBase>
      </div>
    </div>
  );
};

export default Slideshow;
