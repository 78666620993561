import React, {
  useEffect,
  useImperativeHandle,
  useState,
  forwardRef,
  useCallback,
  useMemo,
} from "react";
import { Fragment } from "react";
import { createPortal } from "react-dom";

import * as css from "../styles/Modal.module.scss";
import { isClient } from "../utils/dev";
import { classs } from "../utils/utils";
import ButtonBase from "./ButtonBase";
import * as cssButton from "../styles/ButtonBase.module.scss";
import Close from "@mdi/svg/svg/close-circle.svg";

const createRoot = (id) =>
  isClient &&
  (document.getElementById(id) ??
    document.body.appendChild(
      Object.assign(document.createElement("div"), { id })
    ));

export const Modal = (
  {
    children,
    child,
    className,
    fade = false,
    defaultOpened = false,
    id = "modal-root",
  },
  ref
) => {
  const [isOpen, setIsOpen] = useState(defaultOpened);
  const modalRoot = createRoot(id);

  const close = useCallback(() => setIsOpen(false), []);
  const handle = useMemo(
    () => ({
      open: () => setIsOpen(true),
      close,
    }),
    [close, setIsOpen]
  );

  useImperativeHandle(ref, () => handle, [handle]);

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );

  useEffect(() => {
    if (isOpen && isClient)
      document.addEventListener("keydown", handleEscape, false);
    return () => {
      if (isClient)
        document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  return (
    <Fragment>
      {child && typeof child === "function" ? child(handle) : child ?? ""}
      {modalRoot &&
        createPortal(
          isOpen ? (
            <div className={classs(css.Modal, className, { fade })}>
              <div onClick={close}></div>
              <ButtonBase className={cssButton.IconButton} onClick={close}>
                <Close />
              </ButtonBase>
              <div>{children}</div>
            </div>
          ) : (
            <Fragment></Fragment>
          ),
          modalRoot
        )}
    </Fragment>
  );
};

export default forwardRef(Modal);
