import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import * as css from "../styles/Creation.module.scss";
import * as cssArticles from "../styles/Articles.module.scss";

import leftLaurel from "../assets/images/award_left.webp";
import rightLaurel from "../assets/images/award_right.webp";
import DownloadIcon from "@mdi/svg/svg/download.svg";

import { classs, sum, unique } from "../utils/utils";

import Layout from "../components/Layout";
import ButtonBase from "../components/ButtonBase";
import ArticleButton from "../components/ArticleButton";
import I18n, { LocaleLink } from "../components/I18n";
import { Modal } from "../components/Modal";
import Slideshow from "../components/Slideshow";
import CreationButton from "../components/CreationButton";

const Youtube = ({ props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    style={{ zIndex: 2, width: "100%", height: "100%" }}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill="#FFF"
      d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
);

const formatDate = (date) =>
  new Date(date).toLocaleString("fr-FR", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
const toHHMM = (minutes = 0) => {
  const [hh, mm] = [Math.floor(minutes / 60), minutes % 60].map((n) =>
    String(n).padStart(2)
  );
  return +hh > 0 ? `${hh}h${Math.round(mm)}` : `${Math.round(mm)}min`;
};
const getCredits = (str) =>
  str?.match(/[^\r\n]+/g).map((line) =>
    line.indexOf(":") === -1
      ? { title: line.trim() }
      : {
          role: line.split(":")[0].trim(),
          names: line
            .split(":")[1]
            .split(",")
            .map((str) => str.trim()),
        }
  ) ?? [];

const getYoutubeId = (url) =>
  (url?.match(
    /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  ) ?? [])[1];

const YoutubePlayer = ({ url, autoplay, ...props }) => (
  <iframe
    key={url}
    src={`https://www.youtube-nocookie.com/embed/${getYoutubeId(url)}${
      autoplay ? "?autoplay=1" : ""
    }`}
    frameBorder="0"
    allow={["autoplay", "encrypted-media", "picture-in-picture"].join("; ")}
    {...props}
  />
);

const Film = ({
  pageContext: { locale, locales },
  location: { pathname },
  data: {
    strapi: {
      theme = {},
      creation: {
        data: {
          id: creationId,
          attributes: {
            poster,
            country = "FR",
            title,
            directors: { data: directors = [] } = {},
            synopsis,
            releaseDate,
            duration,
            cast,
            awards,
            status,
            productor,
            factSheet,
            pressKit,
            showReleaseDate,
            photos: { data: photos = [] } = {},
            articles: { data: articles = [] } = {},
            videos: jsonVideos,
            image,
            coverPosition,
            color,
            youtubeUrl,
            imdbUrl,
            wikipediaUrl,
            allocineUrl,
            universCineUrl,
          },
        },
      } = {},
    } = {},
  },
}) => {
  const videos = [
    ...(youtubeUrl ? [{ url: youtubeUrl }] : []),
    ...(Array.isArray(jsonVideos)
      ? jsonVideos.filter((v) => typeof v?.url === "string")
      : []),
  ];
  const withPoster =
    status !== "production" &&
    productor !== "balthuslab" &&
    poster?.data?.attributes?.fileS3;
  const description = `${(directors ?? []).map(
    ({ attributes: { firstName: a, lastName: b } }) =>
      [a, b].filter((v) => v).join(" ")
  )} ${synopsis}`;
  const canonical = (base) => new URL(pathname, base).href;
  const [showCast, setShowCast] = useState(false);
  console.log(directors);
  return (
    <Layout
      theme={theme}
      className={css.Creation}
      locale={locale}
      locales={locales}
      pathname={pathname}
      canonical={pathname}
      title={title}
      meta={(meta, { url }) => [
        ...meta,
        { name: "description", content: description },
        { property: "og:type", content: "video.movie" },
        { property: "og:title", content: title },
        { property: "og:description", content: description },
        { property: "og:image", content: image?.data?.attributes?.url },
        { property: "og:video:release_date", content: releaseDate },
        { property: "og:url", content: canonical(url) },
      ]}
      jsonLd={(jsonLd, { url, oragnization }) => [
        ...jsonLd,
        {
          "@context": "https://schema.org",
          "@type": "Movie",
          url: `${url}${pathname}`,
          name: title,
          image: poster?.data?.attributes?.url ?? image?.data?.attributes?.url,
          dateCreated: releaseDate,
          countryOfOrigin: country,
          director: directors.map(
            ({ firstName: a, lastName: b, imdbUrl, wikipediaUrl, slug }) => ({
              "@type": "Person",
              name: [a, b].filter((v) => v).join(" "),
              sameAs: [imdbUrl, wikipediaUrl].filter((v) => v),
              url: new URL(`personne/${slug}`, url).href,
            })
          ),
          description,
          actor: cast && cast.split(", ").map((item) => item),
          duration,
          productionCompany: oragnization,
          sameAs: [imdbUrl, wikipediaUrl, allocineUrl].filter((v) => v),
          trailer: youtubeUrl && {
            "@type": "VideoObject",
            embedUrl: youtubeUrl,
            description,
            name: title,
            thumbnailUrl: image?.data?.attributes?.url,
            uploadDate: releaseDate,
          },
          isFamilyFriendly: true,
          award: awards && awards.split("\n").map((v) => v),
        },
      ]}
    >
      <div className="player" style={{ "--creation-color": color }}>
        <GatsbyImage
          image={getImage(image?.data?.attributes?.fileS3)}
          objectFit="cover"
          objectPosition={
            coverPosition?.split("_")?.join(" ") ?? "center center"
          }
          alt=""
        />
        {youtubeUrl && (
          <Modal
            child={({ open }) => (
              <ButtonBase className="play" onClick={open}>
                <Youtube />
              </ButtonBase>
            )}
          >
            <YoutubePlayer
              url={youtubeUrl}
              autoplay={true}
              style={{ width: "100%", height: "100%" }}
            />
          </Modal>
        )}
      </div>
      <section className={classs("main", { "contains-poster": withPoster })}>
        {withPoster && (
          <div className="poster">
            <GatsbyImage
              image={getImage(poster?.data?.attributes?.fileS3)}
              objectFit="contain"
              objectPosition="top"
              alt=""
            />
            {universCineUrl && (
              <a
                className="vod"
                href={universCineUrl}
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="20px"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8 5v14l11-7z"></path>
                  </svg>
                  <span>VOD</span>
                </div>
                <StaticImage
                  src="../assets/images/logo/logo_Universcine.webp"
                  alt=""
                  placeholder="blurred"
                  layout="fixed"
                  height={20}
                />
              </a>
            )}
          </div>
        )}
        <div className="informations">
          <h1 translate="no">{title}</h1>
          {directors?.length > 0 && (
            <div className="directors">
              {directors.map(
                ({ id, attributes: { slug, firstName, lastName } }) => (
                  <ButtonBase
                    component={LocaleLink}
                    key={id}
                    to={`/personne/${slug}`}
                  >
                    {[firstName, lastName].filter((v) => v).join(" ")}
                  </ButtonBase>
                )
              )}
            </div>
          )}
          {synopsis && (
            <Markdown>
              {synopsis.replace(/(\d+)\./g, (_, $1) => $1 + "\\.")}
            </Markdown>
          )}
          <div className="details">
            {showReleaseDate &&
              releaseDate &&
              new Date(releaseDate) > new Date(0) && (
                <Fragment>
                  <I18n id="film.release_date" component="span" />
                  <span>{formatDate(releaseDate)}</span>
                </Fragment>
              )}
            {duration > 0 && (
              <Fragment>
                <I18n id="film.duration" component="span" />
                <span>{toHHMM(duration)}</span>
              </Fragment>
            )}
            {cast && (
              <>
                <I18n id="film.cast" component="span" />
                <span>{cast}</span>
              </>
            )}
          </div>
        </div>
      </section>
      {videos?.filter(unique(({ url }) => url))?.length > 0 && (
        <section className="videos">
          <I18n id="title.videos" component="h2" />
          {videos.filter(unique(({ url }) => url)).map(({ url }) => (
            <YoutubePlayer key={url} url={url} />
          ))}
        </section>
      )}
      {photos?.length > 0 && (
        <div className="photos">
          <section>
            <I18n id="title.photos" component="h2" />
            {photos.map(({ attributes: { url, fileS3 } }, i) => (
              <Modal
                key={url}
                child={({ open }) => (
                  <div>
                    <ButtonBase
                      component={GatsbyImage}
                      image={getImage(fileS3)}
                      onClick={open}
                      alt=""
                    />
                    <ButtonBase
                      component="a"
                      href={url}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <DownloadIcon />
                    </ButtonBase>
                  </div>
                )}
              >
                <Slideshow className={css.Slideshow} visible={100000}>
                  {[...photos.slice(i), ...photos.slice(0, i)].map(
                    ({ attributes: { name, url, hqFileS3 } }, j) => (
                      <div key={url} style={{ "--item-n": j }}>
                        <GatsbyImage
                          image={getImage(hqFileS3)}
                          objectPosition="center"
                          objectFit="contain"
                          alt=""
                        />
                        <span>{name}</span>
                        <span>{`${j + 1} / ${photos.length}`}</span>
                      </div>
                    )
                  )}
                </Slideshow>
              </Modal>
            ))}
          </section>
          {pressKit?.data && (
            <div className="files">
              {[pressKit?.data]
                .filter((v) => v)
                .map(({ attributes: { url, name } }) => (
                  <a
                    key={name}
                    href={url}
                    download={name}
                    target="_blank"
                    rel="noopener, noreferrer"
                  >
                    <I18n id="film.press_kit" component="span" />
                    <DownloadIcon />
                  </a>
                ))}
            </div>
          )}
        </div>
      )}
      {awards && (
        <section className="awards">
          <h2>
            <img src={leftLaurel} className="laurel left" />
            <I18n id="title.awards" component="span" />
            <img src={rightLaurel} className="laurel right" />
          </h2>
          <div>
            {awards.split("\n").map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </section>
      )}
      {factSheet && (
        <section className="cast">
          <I18n id="title.cast" component="h2" />
          <div className={classs({ showCast })}>
            {getCredits(factSheet).map(({ title, role, names }, i) =>
              title ? (
                <div key={i} className="category">
                  {title}
                </div>
              ) : (
                role && (
                  <Fragment key={i}>
                    {names.map((name, j) => (
                      <Fragment key={j}>
                        <div className="role">{j === 0 && role}</div>
                        <div>{name}</div>
                      </Fragment>
                    ))}
                  </Fragment>
                )
              )
            )}
          </div>
          <button className="show" onClick={() => setShowCast((s) => !s)}>
            <I18n
              id={showCast ? "button.show_less" : "button.show_more"}
              component="span"
            />
            ...
          </button>
        </section>
      )}
      {articles?.length > 0 && (
        <section className={cssArticles.Articles}>
          <I18n id="title.news" component="h2" />
          {articles.map(({ id, attributes }) => (
            <ArticleButton key={id} {...attributes} />
          ))}
        </section>
      )}
      {sum(
        directors,
        ({ attributes: { creations } }) => creations?.data?.length > 1
      ) && (
        <section className="films">
          <I18n id="title.filmography" component="h2" />
          {directors
            ?.filter(
              ({ attributes: { creations } }) => creations?.data?.length > 0
            )
            ?.flatMap(({ attributes: { creations } }) =>
              creations?.data?.flatMap(({ id, attributes }) => ({
                id,
                attributes,
              }))
            )
            ?.sort(
              (
                { attributes: { releaseDate: a } },
                { attributes: { releaseDate: b } }
              ) => new Date(b).getFullYear() - new Date(a).getFullYear()
            )
            ?.filter(({ id }) => id !== creationId)
            ?.filter(unique(({ id }) => id))
            ?.map(
              ({ id, attributes: { productor, slug, poster }, attributes }) => (
                <CreationButton
                  key={id}
                  variant={
                    (productor === "pelleas" || productor === "other") &&
                    poster?.data
                      ? "poster"
                      : "standard"
                  }
                  to={
                    productor !== "other" && slug
                      ? `/${
                          productor === "balthuslab" ? "artnum" : "film"
                        }/${slug}`
                      : null
                  }
                  {...attributes}
                  watermark={productor === "balthuslab"}
                  withReleaseYear
                />
              )
            )}
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: ID!) {
    strapi {
      creation(id: $id) {
        data {
          id
          attributes {
            slug
            releaseDate: release_date
            showReleaseDate: show_release_date
            coverPosition: cover_position
            color
            duration
            title
            youtubeUrl: youtube_url
            universCineUrl: universcine_url
            synopsis
            awards
            factSheet: fact_sheet
            cast
            status
            productor
            videos
            pressKit: press_kit {
              data {
                id
                attributes {
                  url
                  name
                }
              }
            }
            photos {
              data {
                id
                attributes {
                  url
                  name
                  formats
                  fileS3(minFormat: "medium") {
                    childImageSharp {
                      gatsbyImageData(quality: 90, formats: [AUTO, WEBP])
                    }
                  }
                  hqFileS3: fileS3(minFormat: "xlarge") {
                    childImageSharp {
                      gatsbyImageData(quality: 95, formats: [AUTO, WEBP])
                    }
                  }
                }
              }
            }
            image {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "large") {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1.5625
                        quality: 90
                        layout: FULL_WIDTH
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            poster {
              data {
                id
                attributes {
                  url
                  formats
                  fileS3(minFormat: "xlarge") {
                    childImageSharp {
                      gatsbyImageData(quality: 90, formats: [AUTO, WEBP])
                    }
                  }
                }
              }
            }
            articles(sort: "publication_date:desc") {
              data {
                id
                attributes {
                  title
                  description
                  url: link
                  image {
                    data {
                      id
                      attributes {
                        url
                        formats
                        fileS3(minFormat: "small") {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1
                              quality: 90
                              formats: [AUTO, WEBP]
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            directors {
              data {
                id
                attributes {
                  firstName: first_name
                  lastName: last_name
                  slug
                  imdbUrl: imdb_url
                  allocineUrl: allocine_url
                  wikipediaUrl: wikipedia_url
                  image {
                    data {
                      id
                      attributes {
                        url
                        formats
                        fileS3(minFormat: "small") {
                          childImageSharp {
                            gatsbyImageData(quality: 90, formats: [AUTO, WEBP])
                          }
                        }
                      }
                    }
                  }
                  creations {
                    data {
                      id
                      attributes {
                        title
                        releaseDate: release_date
                        slug
                        color
                        productor
                        status
                        directors {
                          data {
                            id
                            attributes {
                              firstName: first_name
                              lastName: last_name
                            }
                          }
                        }
                        image {
                          data {
                            id
                            attributes {
                              url
                              formats
                              fileS3(minFormat: "small") {
                                childImageSharp {
                                  gatsbyImageData(
                                    aspectRatio: 1.5625
                                    quality: 90
                                    formats: [AUTO, WEBP]
                                  )
                                }
                              }
                            }
                          }
                        }
                        poster {
                          data {
                            id
                            attributes {
                              url
                              formats
                              fileS3(minFormat: "medium") {
                                childImageSharp {
                                  gatsbyImageData(
                                    quality: 90
                                    formats: [AUTO, WEBP]
                                  )
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Film;
